import { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import { ImgixNextBackground } from "@components/imgixNextBackground";
import { PageHeading } from "@components/headings/page";

import { ArrowRight } from "@icons";

import { tagular } from "@cohesion/tagular";
import { keyify } from "@utils/keyify";

import styles from "./styles.module.css";

const getObserver = (ref, callback) => {
  const observer = ref.current;
  if (observer !== null) return observer;

  const handler = entries => {
    // eslint-disable-next-line no-restricted-syntax
    for (const entry of entries) {
      if (
        entry.intersectionRatio >= 0.85 &&
        // set breakpoint threshold to prevent desktop events
        entry?.target?.parentElement?.offsetWidth <= 1024
      ) {
        callback(parseInt(entry.target.dataset.index, 10));
      }
    }
  };

  const newObserver = new IntersectionObserver(handler, {
    root: null,
    rootMargin: "0px",
    threshold: 1.0,
  });

  // eslint-disable-next-line no-param-reassign
  ref.current = newObserver;

  return newObserver;
};

export function Hero3up({ title = "", subheading = "", data }) {
  const trackRef = useRef(null);
  // set state to filter out click events for mobile tracking
  const clickState = useRef(0);
  const [activeSlide, setActiveSlide] = useState(0);

  const refs = useRef([]);
  const observer = useRef(null);
  const addNode = useCallback(node => refs.current.push(node), []);

  useEffect(() => {
    if (!refs?.current[0]) return null;
    if (observer.current) observer.current.disconnect();
    const newObserver = getObserver(observer, index => {
      setActiveSlide(index);
    });

    // eslint-disable-next-line no-restricted-syntax
    for (const node of refs.current) {
      if (node) {
        newObserver.observe(node);
      }
    }

    return () => newObserver.disconnect();
  }, [refs]);

  useEffect(() => {
    // filter out events that are activated via click
    if (clickState.current === activeSlide) {
      clickState.current = null;
      return;
    }
    // track slide event for mobile
    tagular("swipe", {
      actionOutcome: "SLIDE",
      webElement: {
        location: "SECTION",
        elementType: "SLIDER",
        position: "HERO",
        text: "SWIPE",
      },
    });
  }, [activeSlide]);

  const scroll = left => {
    trackRef.current.scroll({
      left,
      behavior: "smooth",
    });
  };

  const handlePaginationClick = i => {
    scroll(refs.current[i].offsetLeft);
    // set state to filter out click events for mobile tracking
    clickState.current = i;
    tagular("click", {
      actionOutcome: "SLIDE",
      outboundUrl: "",
      webElement: {
        location: "SECTION",
        elementType: "SLIDER",
        position: "HERO",
        text: "PAGINATION",
      },
    });
  };

  const trackInteraction = text => {
    tagular("click", {
      actionOutcome: "INTERNALLINK",
      outboundUrl: "",
      webElement: {
        location: "SECTION",
        elementType: "LINK",
        position: "HERO",
        text,
      },
    });
  };

  return (
    <section className="relative">
      <div className="absolute left-0 right-0 z-10 max-w-full mx-auto top-32 lg:top-64 w-content">
        <div className="flex justify-center w-4/5 m-auto lg:w-content lg:max-w-4xl">
          <div className="max-w-4xl leading-none text-center text-white touch-action-none">
            <PageHeading className="max-w-4xl leading-none text-center text-white touch-action-none">
              {(title && (
                <>
                  {subheading && (
                    <span className="block text-2xl font-semibold leading-tight md:text-4xl font-display">
                      {subheading}
                    </span>
                  )}{" "}
                  {title}
                </>
              )) || (
                <>
                  Discover{" "}
                  <span className="whitespace-nowrap">story-worthy</span> travel
                  moments
                </>
              )}
            </PageHeading>
          </div>
        </div>
      </div>

      <div
        ref={trackRef}
        className="flex overflow-x-auto scroll-snap-x-mandatory"
      >
        {data.map((content, i) => {
          const {
            href: contentHref,
            image: contentImage,
            title: contentTitle,
          } = content;

          return (
            <div
              key={keyify(contentTitle)}
              ref={addNode}
              data-index={i}
              className="relative flex-none w-screen snap-align-start lg:flex-1 min-h-screen-4/5 lg:min-h-184"
            >
              <ImgixNextBackground
                src={contentImage}
                overlay="custom"
                overlayClasses={cn("flex items-end", styles.panel)}
                priority
                imgixParams={{
                  w: 600,
                  h: 800,
                  auto: "format",
                  dpr: 1,
                  fit: "crop",
                }}
              >
                <a
                  href={contentHref}
                  className="flex items-end justify-between w-full mx-4 mb-6 text-lg leading-tight text-white group lg:mx-6 lg:mb-8 font-display lg:text-2xl card-link"
                  onClick={() => trackInteraction(contentTitle.toUpperCase())}
                  onKeyPress={() =>
                    trackInteraction(contentTitle.toUpperCase())
                  }
                  role="button"
                  tabIndex={0}
                >
                  <span className="w-10/12 md:w-2/3">{contentTitle}</span>
                  <ArrowRight className="transition-transform duration-300 ease-in-out group-hover:transform group-hover:translate-x-2" />
                </a>
              </ImgixNextBackground>
            </div>
          );
        })}
      </div>

      <div className="bg-black lg:hidden">
        <nav className="container flex justify-center max-w-xs px-24 gap-x-2">
          {data.map((_, i) => (
            <button
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              className={cn(
                "block py-6",
                i === activeSlide ? "flex-2" : "flex-1"
              )}
              onClick={() => handlePaginationClick(i)}
              type="button"
              aria-label={`Go to slide ${i + 1}`}
            >
              <div
                className={cn(
                  "block h-five rounded transition w-full",
                  i === activeSlide ? "bg-white" : "bg-black-400"
                )}
              />
            </button>
          ))}
        </nav>
      </div>
      <style jsx>{`
        .card-link:after {
          content: " ";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
      `}</style>
    </section>
  );
}

Hero3up.propTypes = {
  title: PropTypes.string,
  subheading: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
    })
  ).isRequired,
};
